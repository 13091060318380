<template>
  <div class="mini-cos no-products" id="mini-cos">
    <div class="wrp-footer-cos">
      <div class="smg load-fc"></div>
      <span class="load-fc">Nu ai niciun produs in cos!</span>
    </div>
  </div>
</template>

<style scoped>
.mini-cos {
  width: 385px;
  background-color: #fff;
  z-index: 12;
  position: absolute;
  right: 1px;
  top: 54px;
  -moz-border-radius: 5px;
  -moz-border-radius-topright: 0;
  cursor: default;
}

.mini-cos.no-products {
  border-radius: 50px;
  width: 350px;
  text-align: center;
  padding: 10px;
  margin-top: 5px;
  font-family: Montserrat Bold;
  border-radius: 50px;
}

.smg {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
  position: absolute;
  right: 20px;
  top: -5px;
}

.load-fc {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  font-family: "Montserrat Bold";
}
</style>
